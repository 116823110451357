import { tenantUpdate as tenantUpdateSchema } from 'models/tenantModel'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { editTenant, getTenant } from 'services/apiTenants'
import { validateObject, validateProperty } from 'utils/validator'

import { hideLoading, showLoading } from 'utils/functions'
import EditView from './EditView'

// Constants
import { editTenantMeta } from 'services/apiApps'
import { enabledApps as enabledAppsConst } from 'utils/Constants'

const newTenant = {
  tenantId: '',
  name: '',
  address: '',
  city: '',
  language: 'en',
  email: '',
  webSiteUrl: '',
  uniqueName: '',
  enabledApps: [],
  enabled: true,
  hasSubdomain: false,
  colorCode: '',
  secondColorCode: '',
  imageLogo: '',
  type: ''
}

const newTenantState = {
  tenantId: '',
  name: '',
  address: '',
  city: '',
  language: '',
  email: '',
  webSiteUrl: '',
  uniqueName: '',
  enabledApps: '',
  enabled: '',
  hasSubdomain: '',
  colorCode: '',
  secondColorCode: '',
  imageLogo: '',
  type: ''
}

let _isMounted = false
let tenantId

async function onSubmit(props) {
  const tenant = formatIsoDate({ ...props.tenant })

  try {
    const success = await handleTenantEdition(props.tenantId, tenant)

    if (success && _isMounted) {
      props.setCreateSuccess(true)

      setTimeout(() => {
        if (_isMounted) {
          props.history.push('/admin/tenants/index')
          props.setCreateSuccess(false)
        }
      }, 1500)
    } else {
      throw new Error('Tenant edition failed.')
    }
  } catch (error) {
    const errorMessage = error.message || 'Something went wrong, please try again later.'
    props.setValidationMessage(errorMessage)
    props.setCreateError(true)
  } finally {
    hideLoading(props.setPageIsLoading)
  }
}

async function handleTenantEdition(tenantId, tenant) {
  const resultEdition = await editTenant(tenantId, tenant)

  if (!resultEdition.success) {
    throw new Error(`Tenant edition error ---> ${resultEdition.message}`)
  }

  const resultEditionMeta = await editTenantMeta(tenantId, tenant)

  if (!resultEditionMeta.success) {
    throw new Error(`Meta edition error ---> ${JSON.stringify(resultEditionMeta.error)}`)
  }

  return true
}

function formatIsoDate(tenant) {
  tenant.enabledApps.forEach(app => {
    app.startDate = new Date(app.startDate).toISOString()
    app.endDate = new Date(app.endDate).toISOString()
  })
  return tenant
}

function formatDate(tenant) {
  tenant.enabledApps.forEach(app => {
    app.startDate = new Date(app.startDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    app.endDate = new Date(app.endDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  })
  return tenant
}

async function getTenantEdit(props, callback) {
  let editTenant = await getTenant(tenantId)
  if (editTenant.success && _isMounted) {
    const tenant = formatDate({ ...editTenant.data })
    newTenant.enabledApps.forEach(item => {
      let found = tenant.enabledApps.findIndex(element => element.key === item.key)
      if (found === -1) {
        tenant.enabledApps.push(item)
      }
    })
    props.setTenant(tenant)
  }

  setTimeout(function() {
    props.setTenantState({ ...newTenantState })
  }, 100)
  callback(props.setPageIsLoading)
}

function enabledApps() {
  let item
  let enabledAppsNew = []
  let enabledAppsNewState = {}
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let endDate = new Date()
  endDate = endDate.setDate(endDate.getDate() + 15)
  endDate = new Date(endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  for (item in enabledAppsConst) {
    enabledAppsNew.push({
      freeTrial: enabledAppsConst[item].freeTrial,
      name: enabledAppsConst[item].name,
      suscriptionType: 'paid',
      startDate: currentDate,
      endDate: endDate,
      key: item,
      active: false
    })
    enabledAppsNewState[item] = {
      suscriptionType: '',
      startDate: '',
      endDate: '',
      active: ''
    }
  }
  newTenant.enabledApps = [...enabledAppsNew]
  newTenantState.enabledApps = { ...enabledAppsNewState }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('tenant', 'setTenant', { ...newTenant }),
  withState('tenantId', 'setTenantId', undefined),
  withState('tenantState', 'setTenantState', { ...newTenantState }),
  withState('createSuccess', 'setCreateSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onFieldChange: props => (field, value, enabledApp = null) => {
      if (field === 'startDate' || field === 'endDate') {
        if (typeof value === 'object' && value !== null) {
          value = value._d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        }
      }
      let isValid = false
      let valid = ''
      if (enabledApp) {
        const i = props.tenant.enabledApps.findIndex(x => x.key === enabledApp)
        props.tenant.enabledApps[i][field] = value
        isValid = validateProperty(tenantUpdateSchema, props.tenant.enabledApps[i], field).isValid
        if (isValid) {
          valid = 'success'
        } else {
          valid = 'error'
        }
        props.tenantState.enabledApps[enabledApp][field] = valid
      } else {
        props.tenant[field] = value
        isValid = validateProperty(tenantUpdateSchema, props.tenant, field).isValid
        if (isValid) {
          valid = 'success'
        } else {
          valid = 'error'
        }
        props.tenantState[field] = valid
      }
      props.setTenantState(props.tenantState)
      props.setTenant(props.tenant)
    },
    onTenantSave: props => async () => {
      props.setIsLoadingSave(true)

      let validation = validateObject(tenantUpdateSchema, props.tenant)
      if (validation.isValid) {
        showLoading()
        await onSubmit(props)
      } else {
        let field
        for (field in props.tenant) {
          if (validation.errors[field]) {
            props.tenantState[field] = 'error'
          }
        }
        props.setTenantState(props.tenantState)
      }
      props.setIsLoadingSave(false)
    },
    onClear: props => () => {
      props.history.push(`/admin/tenants/index`)
    }
  }),
  lifecycle({
    componentDidMount() {
      _isMounted = true
      showLoading(this.props.setPageIsLoading)
      tenantId = this.props.match.params.id
      enabledApps()
      this.props.setTenantId(tenantId)
      this.props.setTenant({ ...newTenant })
      this.props.setTenantState({ ...newTenantState })
      this.props.setCreateSuccess(false)
      getTenantEdit(this.props, hideLoading)
    }
  })
)(EditView)
